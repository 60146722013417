import React from "react";

import { Layout } from "~/components";
import { HeliWeddings as HeliWeddingsView } from "~/views";

const HeliWeddings: React.FunctionComponent = () => {
  return (
    <Layout title="Heli-Wedings">
      <HeliWeddingsView />
    </Layout>
  );
};

export default HeliWeddings;
